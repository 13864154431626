import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { COLORS } from 'config/theme/baseTheme';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexDirection: "column",
      padding: theme.spacing(1, 2),
      backgroundColor: COLORS.ZEUS,
      '& .footerContent': {
        flexDirection: "column",
        '& .footerIcon': {
            width: 148,
            height: 75
        },
        [theme.breakpoints.up('sm')]: {
            alignItems: "center"
        }
     },
      '&.webView':{
        display: "none",
        [theme.breakpoints.up('sm')]: {
          display: "block"
        }
      }
    }
  }),
);