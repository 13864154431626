import OrderTracking from 'OrderTracking';
import OrderTrackingMkp from './OrderTrackingMkpNonFood';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFound from './common/NotFoundPage';
import AppConstants from './constants';

const Routes = () => {

  // const { isLoggedIn, isAdmin } = props;
  return (
    <Switch>
      <Route
        path={AppConstants.ROUTES.TRACK_ORDER}
        component={OrderTracking}
      />
      <Route
        path={AppConstants.ROUTES.TRACK_ORDER_MKP}
        component={OrderTrackingMkp}
      />
      <Route
        component={NotFound}
      />
    </Switch>
  );
};

export default Routes;
