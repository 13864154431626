const AppConstants: { [key: string]: any } = {
    en: {
        DATE_FORMAT_UI: 'DD MMM YY',
        DATE_FORMAT_UI_FULL: 'dddd DD MMMM',
        DATE_FORMAT_BACKEND: 'YYYY-MM-DDTHH:mm:ss',
        TIME_FORMAT_UI: "HH:mm",
        TIME_FORMAT_UI_AM_PM: "hh:mm A",
        EARLY_TIME: "Hurray! Your order will be delivered early by ",
        DELAY_TIME: "Sorry! Your order will be delayed by ",
        MINUTES: 'mins',
        DELIVERY_FAILED_MSG1: "Order amount will be refunded if already paid.",
        DELIVERY_FAILED_MSG2: "Contact customer support for further details.",
        ORDER_CANCELLED_MSG1: "We’re sorry that your order has been cancelled.",
        ORDER_CANCELLED_MSG2: "Refund will be initiated if already paid.",
        TECH_ISSUE_MSG1: "Our team is resolving the issue.",
        TECH_ISSUE_MSG2: "Please wait for updated or contact our customer support.",
        ARRIVAL_TIME: "Arrival Time",
        ARRIVING_IN: "Arriving by",
        ARRIVED: "Arrived",
        DELIVERING_TO: "Delivering to",
        DELIVERY_STATUS: {
            ORDER_PLACED: "Order placed",
            ORDER_BEING_PREPARED: "Order being prepared",
            ORDER_ON_THE_WAY: "Order on the way",
            ORDER_DELIVERED: "Order delivered",
            DELIVERY_FAILED: "Not Delivered",
            ORDER_CANCELLED: "Cancelled"
        },
        DELIVERY_OMS_STATUS_MSGS: {
            WAITING: "Your order has been placed",
            READY: "Your order has been placed",
            EXPORTED: "Your order is being prepared",
            PICKING_STARTED: "Your order is being prepared",
            PACKING_COMPLETE: "Your order is being prepared",
            READY_TO_DISPATCH: "Your order is being prepared",
            DISPATCHED: "Your order is on the way",
            PENDING_DELIVERY: "Your order is arriving soon",
            DELIVRED: "Your order has been delivered",
            DELIVERY_FAILED: "Sorry! we couldn’t deliver your order",
            CANCELLED: "We are sorry your order has been cancelled"
        },
        NEED_HELP_NUM_JORDAN: "065000015",
        NEED_HELP_NUM: "800-73232",
        CUSTOMER_SUPPORT: "Customer Support",
        ORDER_NUMBER: "Order Number"
    },
    ar: {
        DATE_FORMAT_UI: 'DD MMM YY',
        DATE_FORMAT_UI_FULL: 'dddd DD MMMM',
        DATE_FORMAT_BACKEND: 'YYYY-MM-DDTHH:mm:ss',
        TIME_FORMAT_UI: "HH:mm",
        TIME_FORMAT_UI_AM_PM: "hh:mm A",
        EARLY_TIME: "أخبار رائعة! سيتم توصيل طلبك مبكراً خلال  ",
        DELAY_TIME: "عذراً! سيتأخر توصيل طلبك",
        MINUTES: 'دقيقة',
        DELIVERY_FAILED_MSG1: "سيتم إرجاع مبلغ الطلب إذا كان قد تم الدفع مسبقاً.",
        DELIVERY_FAILED_MSG2: "الرجاء الاتصال بمركز خدمة العملاء لمزيد من التفاصيل.",
        ORDER_CANCELLED_MSG1: "نأسف لإلغاء طلبك من كارفور.",
        ORDER_CANCELLED_MSG2: "سيبدأ إرجاع مبلغ الطلب إذا كان قد تم الدفع مسبقاً.",
        TECH_ISSUE_MSG1: "يعمل فريقنا على حل المشكلة.",
        TECH_ISSUE_MSG2: "الرجاء الانتظار وسنبقيك على اطلاع بكل جديد، أو يمكنك الاتصال بفريق خدمة العملاء",
        ARRIVAL_TIME: "الوقت المتوقع للتوصيل",
        ARRIVING_IN: "سيصلك الطلب خلال ",
        ARRIVED: "تم التوصيل",
        DELIVERING_TO: "التسليم إلى",
        DELIVERY_STATUS: {
            ORDER_PLACED: "تم تسجيل الطلب",
            ORDER_BEING_PREPARED: "جاري تحضير الطلب",
            ORDER_ON_THE_WAY: "طلبك في طريقه إليك",
            ORDER_DELIVERED: "تم تسليم الطلب",
            DELIVERY_FAILED: "لم يتم تسليم الطلب",
            ORDER_CANCELLED: "تم إلغاء الطلب"
        },
        DELIVERY_OMS_STATUS_MSGS: {
            WAITING: "تم تسجيل طلبك من كارفور ",
            READY: "تم تسجيل طلبك من كارفور ",
            EXPORTED: "جاري تحضير طلبك من كارفور",
            PICKING_STARTED: "جاري تحضير طلبك من كارفور ",
            PACKING_COMPLETE: "جاري تحضير طلبك من كارفور ",
            READY_TO_DISPATCH: "جاري تحضير طلبك من كارفور ",
            DISPATCHED: "طلبك من كارفور  في طريقه إليك",
            PENDING_DELIVERY: "طلبك من كارفور  سيصلك قريباً ",
            DELIVRED: "تم تسليم طلبك من كارفور ",
            DELIVERY_FAILED: "عذراً! لم نتمكن من توصيل طلبك",
            CANCELLED: "نأسف لإلغاء طلبك من كارفور ."
        },
        NEED_HELP_NUM_JORDAN: "065000015",
        NEED_HELP_NUM: "800-73232",
        CUSTOMER_SUPPORT: "دعم العملاء",
        ORDER_NUMBER: "رقم الطلب"
    },
    ka: {
        DATE_FORMAT_UI: 'DD MMM YY',
        DATE_FORMAT_UI_FULL: 'dddd DD MMMM',
        DATE_FORMAT_BACKEND: 'YYYY-MM-DDTHH:mm:ss',
        TIME_FORMAT_UI: "HH:mm",
        TIME_FORMAT_UI_AM_PM: "hh:mm A",
        EARLY_TIME: "თქვენი შეკვეთის მიწოდება მოხდება ადრე",
        DELAY_TIME: "ბოდიშს გიხდით, თქვენი შეკვეთა დაგვიანდება ",
        MINUTES: ' წუთით',
        DELIVERY_FAILED_MSG1: "თუ შეკვეთის თანხა გადახდილია ის დაბრუნდება",
        DELIVERY_FAILED_MSG2: "დაუკავშირდით კლიენტთა მომსახურების სერვისს შემდგომი ნაბიჯებისთვის",
        ORDER_CANCELLED_MSG1: "ვწუხვართ რომ თქვენი შეკვეთა გაუქმდა",
        ORDER_CANCELLED_MSG2: "თუ შეკვეთის თანხა გადახდილია მისი დაბრუნების პროცესი დაიწყება",
        TECH_ISSUE_MSG1: "ჩვენი გუნდი მუშაობს ხარვეზის გამოსწორებაზე",
        TECH_ISSUE_MSG2: "გთხოვთ დაელოდოთ სიახლეს ან დაუკავშირდით კლიენტთა მომსახურების სერვისს",
        ARRIVAL_TIME: "მიწოდების დრო",
        ARRIVING_IN: "მოსულია",
        ARRIVED: "მოსულია",
        DELIVERING_TO: "მიტანა მისამართზე",
        DELIVERY_STATUS: {
            ORDER_PLACED: "შეკვეთა განთავსებულია",
            ORDER_BEING_PREPARED: "შეკვეთა მზადდება",
            ORDER_ON_THE_WAY: "შეკვეთა გზაშია",
            ORDER_DELIVERED: "შეკვეთა მიტანილია",
            DELIVERY_FAILED: "არ არის მიტანილი",
            ORDER_CANCELLED: "გაუქმებულია"
        },
        DELIVERY_OMS_STATUS_MSGS: {
            WAITING: "თქვენი შეკვეთა განთავსებულია",
            READY: "თქვენი შეკვეთა განთავსებულია",
            EXPORTED: "თქვენი შეკვეთა მზადდება",
            PICKING_STARTED: "თქვენი შეკვეთა მზადდება",
            PACKING_COMPLETE: "თქვენი შეკვეთა მზადდება",
            READY_TO_DISPATCH: "თქვენი შეკვეთა მზადდება",
            DISPATCHED: "თქვენი შეკვეთა გზაშია",
            PENDING_DELIVERY: "თქვენი შეკვეთა მალე მოვა",
            DELIVRED: "თქვენი შეკვეთა მიწოდებულია",
            DELIVERY_FAILED: "ბოდიშს გიხდით, თქვენი შეკვეთის მიწოდება შეუძლებელია",
            CANCELLED: "ვწუხვართ რომ თქვენი შეკვეთა გაუქმდა"
        },
        NEED_HELP_NUM_JORDAN: "065000015",
        NEED_HELP_NUM: "800-73232",
        CUSTOMER_SUPPORT: "კლიენტთა მომსახურების სერვისი",
        ORDER_NUMBER: "შეკვეთის ნომერი"
    },
    BUFFER_TIME: 4
};

export default AppConstants;