import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

export const COLORS = {
  PRIMARY_MAIN: "#0E5AA7",
  PRIMARY_LIGHT: "#92B4D7",
  PRIMARY_DARK: "#0E5A80",
  SECONDARY_MAIN: "#F5F5F5",
  SECONDARY_LIGHT: "#EEEEEE",
  SECONDARY_DARK: "#E6E6E6",
  TEXT_PRIMARY: "#252525",
  GRADIENT_BLUE: "#91AECC",
  BOX_SHADOW_PRIMARY: "rgba(14, 90, 167, 0.2)",
  TRANSPARENT_BLUE: "rgba(10, 67, 124, 0.05)",
  TRANSPARENT_BLUE_BORDER: "rgba(10, 67, 124, 0.2)",
  WHITE: "#FFFFFF",
  BLACK_SHADOW: "rgba(0,0,0,0.05)",
  LIGHT_BLACK_SHADOW: "rgba(0, 0, 0, 0.15)",
  BLACK_INPUT_BORDER: "rgba(0, 0, 0, 0.23)",
  BLACK_INPUT_LABEL: "rgba(0, 0, 0, 0.54)",
  GRAY_DARK: "#B2B2B2",
  ALTO: "#DCDCDC",
  BLACK: "#000000",
  GOLDEN_BELL: "#E3870F",
  GOLDEN_YELLOW_LIGHT: "#F2C892",
  AZURE_BLUE: "#0A437C",
  PERSIAN_GREEN: "#009688",
  PERSIAN_GREEN_LIGHT: "#8CCFC9",
  PERSIAN_GREEN_DARK: "#007F73",
  DARK_RED: "#F34041",
  LIGHT_RED: "#F79C9D",
  DOVE_GRAY: "#666666",
  DOVE_GRAY_LIGHT: "rgba(102,102,102,0.6)",
  SILVER: "#C4C4C4",
  COPY: "#4D4D4D",
  TRANSPARENT: "transparent",
  LOADER_BACKGROUND: "rgba(255, 255, 255, 0.38)",
  TABLE_ROW_SELECTED: "#F3F6F8",
  SUB_HEADER_SELECTED: "#E9EFF2",
  MINE_SHAFT: "#2D2D2D",
  ROUTE_CONTAINER_BACKGROUND: '#FAFAFA',
  FOOTER_COUNT_BACKGROUND: 'rgba(37,37,37, 0.85)',
  MINE_SHAFT_LIGHT: "#F4F4F4",
  MADISON_LIGHT: "#F2F5F8",
  MAROON: "#843D77",
  SUCCESS_GREEN: "#FFDF28",
  LIGHT_GREEN: "#63C98E",
  PICTON_BLUE: "#41BCEA",
  CREAM_CAN: "#F5C45D",
  VISTA_BLUE: "#7FD2A3",
  SEA_GREEN: "#319E60",
  CORAL_PINK: "#F37172",
  ALERT_ORANGE: '#F3BA40',
  MINSK_DARK: '#3B3B98',
  STATUS_YELLOW: "#F79E1B",
  MINSK_LIGHT: '#A6A6D0',
  SCAMPI: "#5858A7",
  SEA_GREEN2: "#267949",
  LUCKY: "#A6911A",
  DUSTY_GRAY: "#979797",
  JUNGLE_GREEN: "#26A599",
  TROPICAL_BLUE: "#C7EBF9",
  CATSKILL_WHITE: "#DFE8F2",
  BLUE_HAZE: "#CDCDE5",
  BARLEY_WHITE: "#FFF6C9",
  DOUBLE_PEARL_LUSTA: "#FCEECF",
  JAGGED_ICE: "#BFE4E1",
  TARA: "#CEEEDB",
  ASTRAL: "#3172B4",
  OLD_GOLD: "#CF9E36",
  PIMPKIN_SKIN: "#C1720C",
  BLUE_STONE: "#006158",
  OCEAN_GREEN: "#3ABA71",
  DUSTY_GRAY_LIGHT: "#969696",
  SELAGO: "#ECF4FD",
  BOULDER: "#787878",
  CONCRETE: "#F2F2F2",
  TORY_BLUE: "#0B5AA6",
  SILVER_LIGHT: "rgba(196, 196, 196, 0.6)",
  ZEUS: "#31261D",
  BLACK_LIGHT: "#1F1F1F"
};

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Montserrat',
      'Roboto',
      'Helvetica',
      'sans-serif'
    ].join(','),
    fontWeightRegular: 500
  },
  palette: {
    type: 'light',
    primary: {
      main: COLORS.PRIMARY_MAIN,
      light: COLORS.PRIMARY_LIGHT,
      dark: COLORS.PRIMARY_DARK,
    },
    secondary: {
      main: COLORS.SECONDARY_MAIN,
      light: COLORS.SECONDARY_LIGHT,
      dark: COLORS.SECONDARY_DARK,
    },
    error: {
      main: red.A400,
    },
    background: {
      default: COLORS.WHITE,
    },
    text: {
      primary: COLORS.TEXT_PRIMARY
    }
  },
  overrides: {
    MuiTypography: {
      body1: {
        lineHeight: 1
      }
    }
  }
});

export default theme;