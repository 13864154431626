import React from 'react';
import Grid from '@material-ui/core/Grid';
import { ReactComponent as mafLogoIcon } from './../../assets/icons/logo-icon.svg';
import { useStyles } from './Header.styles';
import { withRouter } from 'react-router-dom';
import { SvgIcon } from '@material-ui/core';

const Header = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid className="headerItem left" item>
        <SvgIcon className="mafLogoIcon" viewBox="0 0 138 34" component={mafLogoIcon} />
      </Grid>
    </Grid>
  );
};

export default withRouter(Header);
