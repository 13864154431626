import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from './NotFound.styles';

const NotFound: any = () => {
    const classes = useStyles();
    return (
        <Grid className={classes.root} container>
            <Typography variant="h3" component="h3">NOT FOUND</Typography>
        </Grid>
    );
}

export default NotFound;