import { combineReducers } from "@reduxjs/toolkit";
import commonSlice from "./common/commonSlice";
import { CommonState } from "./common/commonSlice.types";

const reducers = {
  common: commonSlice
};

export interface AppState {
  common: CommonState;
}

export default combineReducers(reducers);
