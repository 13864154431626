import React from 'react';
import Grid from '@material-ui/core/Grid';
import { ReactComponent as footerContent } from './../../assets/icons/footer-content.svg';

import { useStyles } from './Footer.styles';
import { withRouter } from 'react-router-dom';
import { SvgIcon } from '@material-ui/core';

const Footer = () => {
  const classes = useStyles();

  return (
    <Grid container className={`${classes.root} webView`}>
      <Grid className="orderItem footer" item>
              <Grid className="footerContent" container>
                  <SvgIcon className="footerIcon" viewBox="0 0 148 60" component={footerContent} />
              </Grid>
          </Grid>
    </Grid>
  );
};

export default withRouter(Footer);
