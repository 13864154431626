import React, { useEffect, useState } from 'react';
import Header from './common/Header';
import Footer from './common/Footer';
import Routes from './routes';
import { withRouter } from 'react-router-dom';
import AppConstants from './constants/';
import { useLocation } from "react-router-dom";

const App = () => {
  
  const [isNonFood, setIsNonFood] = useState(false);
  const location = useLocation();

  useEffect(() => {
      if (AppConstants.ROUTES.TRACK_ORDER_MKP === location.pathname) {
        setIsNonFood(true)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <>
      {!isNonFood ? <Header /> : null }
      <main className={`routesContainer`}>
        <Routes  />
      </main>
      {!isNonFood ? <Footer /> : null }
    </>
  );
};

export default withRouter(App);
