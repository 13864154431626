const CommonConstants = {
    OMS_STATUS:{
        WAITING: 'WAITING',
        READY: 'READY',
        EXPORTED: 'EXPORTED',
        PICKING_STARTED: 'PICKING_STARTED',
        PACKING_COMPLETE: 'PACKING_COMPLETE',
        READY_TO_DISPATCH: 'READY_TO_DISPATCH',
        DISPATCHED: 'DISPATCHED',
        PENDING_DELIVERY: 'PENDING_DELIVERY',
        DELIVERED: 'DELIVRED',
        DELIVERY_FAILED: 'DELIVERY_FAILED',
        CANCELLED: 'CANCELLED'
    },
    OMS_TERMINAL_STATUS:[ 'DELIVERY_FAILED','CANCELLED', 'DELIVRED', "DELIVERED"],
    FF_TERMINAL_STATUS:[ 'DELIVERY_FAILED','CANCELLED', 'DELIVERED', 'PARTIALLY_DELIVERED'],
    FF_STATUS:{
        NEW: 'NEW',
        OUT_FOR_DELIVERY: "OUT_FOR_DELIVERY",
        ON_HOLD:"ON_HOLD",
        PICKING_STARTED: 'PICKING_STARTED',
        PACKING_COMPLETE: 'PACKING_COMPLETE',
        READY_TO_DISPATCH: 'READY_TO_DISPATCH',
        DISPATCHED: 'DISPATCHED',
        PENDING_DELIVERY: 'PENDING_DELIVERY',
        DELIVERED: 'DELIVERED',
        DELIVERY_FAILED: 'DELIVERY_FAILED',
        CANCELLED: 'CANCELLED',
        PARTIALLY_DELIVERED:"PARTIALLY_DELIVERED"
    },
}

export default CommonConstants;