import axios from "axios";
import { NetworkCallPayload } from "./networkHandler.types";
import { basicAuthorization, liveTrackingUrl } from "../config/Api.config";
import { replaceUrlVariables } from "../utils/updateUrlParams";

const networkDefaultSetting = (isReport?: boolean, source ?: string) => {
  axios.defaults.baseURL = liveTrackingUrl;
  axios.defaults.headers.common["Authorization"] = basicAuthorization;  
  if(source){
    axios.defaults.headers.common["source"] = 'portal';
  }
};

  const networkHandler = async (params: NetworkCallPayload, isReport?: boolean, source ?: string) => {
  const { url, urlParams, ...restApiOptions } = params;
  let updateUrl = url;
  if (urlParams) {
    updateUrl = replaceUrlVariables(url, urlParams);
  }
  // axios default configs
  networkDefaultSetting(isReport, source);
  return await axios.request({ url: updateUrl, ...restApiOptions });
};

export default networkHandler;
