import { StepConnector, withStyles } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { COLORS } from 'config/theme/baseTheme';

export const StepperTimelineConnector = withStyles({
    alternativeLabel: {
        top: 0,
        left: 0,
        right: 0,
        '&.placed': {
            '& .MuiStepConnector-line': {
                borderColor: COLORS.CONCRETE
            }
        },
        '&.preparing': {
            '& .MuiStepConnector-line': {
                borderColor: COLORS.CONCRETE
            }
        },
        '&.onTheWay': {
            '& .MuiStepConnector-line': {
                borderColor: COLORS.CONCRETE
            }
        },
        '&.delivered': {
            '& .MuiStepConnector-line': {
                borderColor: COLORS.PERSIAN_GREEN
            }
        },
        '&.cancelled': {
            '& .MuiStepConnector-line': {
                borderColor: COLORS.DARK_RED
            }
        },
    },
    active: {
        '& $line': {
            borderColor: COLORS.CONCRETE,
        },
    },
    line: {
        borderColor: COLORS.CONCRETE,
        borderTopWidth: 5,
        borderRadius: 100,
    },
})(StepConnector);

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "100%",
            flexDirection: "column",
            //paddingTop: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                paddingTop: theme.spacing(0),
            },
            '& .topHeader':{
                '&.webView': {
                    display: "none",
                },
                [theme.breakpoints.up('sm')]: {
                    '&.webView': {
                        display: "flex"
                    }
                },
            },
            '& .bottomHeading':{
                '&.mobileView': {
                    display: "flex",
                    // '& .progressTimeline': {
                    //     paddingTop: '0 !important'
                    // },
                    '& .headerItem': {
                        width: '25%'
                    }
                },
                [theme.breakpoints.up('sm')]: {
                    '&.mobileView': {
                        display: "none"
                    }
                },
            },
            '& .orderItem': {
                '&.heading': {
                    position: "relative"
                },
                '& .bold': {
                    fontWeight: "600 !important"
                },
                '& .minutes': {
                    float: "right"
                },
                '& .green': {
                    color: `${COLORS.PERSIAN_GREEN} !important`
                },
                '& .red': {
                    color: `${COLORS.DARK_RED} !important`
                },
                '& .contact a': {
                    textDecoration: "none",
                    color: theme.palette.primary.main
                },
                '& .headingContainer': {
                    padding: theme.spacing(2, 2, 8),
                    flexDirection: "column",
                    '& .headingTitle': {
                        fontSize: 24,
                        fontWeight: 600,
                        color: COLORS.BLACK_LIGHT,
                    },
                    '& .headingSubtitle': {
                        fontSize: 12,
                        fontWeight: 400
                    },
                    [theme.breakpoints.up('sm')]: {
                        alignItems: "center",
                    },
                },
                '& .progressBar': {
                    position: "absolute",
                    height: 88,
                    width: "calc(100% - 32px)",
                    backgroundColor: COLORS.WHITE,
                    left: 16,
                    bottom: "-42px",
                    zIndex: 10,
                    border: `1px solid ${COLORS.CONCRETE}`,
                    borderRadius: theme.spacing(1),
                    '& .progressHeaderTimer':{
                        paddingTop: 8
                    },
                    '& .progressContainer': {
                        flexDirection: "column",
                        padding: theme.spacing(1.5, 1.5, 2),
                        '& .headerContainer': {
                            '& .deliveryStep':{
                                width: '100%',
                                backgroundColor: COLORS.CONCRETE,
                                //borderRadius: theme.spacing(1),
                                marginTop: 8,
                                height: 8,
                                // '&.first': {
                                //     borderRadius: theme.spacing(1,0,0,1),
                                // },
                                // '&.last': {
                                //     borderRadius: theme.spacing(0,1,1,0),
                                // },
                                '&.blue':{
                                    backgroundColor: COLORS.TORY_BLUE,
                                    borderRadius: theme.spacing(1),
                                    '&.radius1':{
                                        borderRadius: theme.spacing(1, 0, 0, 1),
                                    },
                                    '&.radius2':{
                                        borderRadius: theme.spacing(0, 1, 1, 0),
                                    },
                                    '&.radius21':{
                                        borderRadius: theme.spacing(0, 0, 0, 0),
                                    },
                                    '&.radius3':{
                                        borderRadius: theme.spacing(0, 1, 1, 0),
                                    }
                                },
                                '&.green':{
                                    backgroundColor: COLORS.PERSIAN_GREEN,
                                    borderRadius: theme.spacing(1),
                                    '&.radius1':{
                                        borderRadius: theme.spacing(1, 0, 0, 1),
                                    },
                                    '&.radius2':{
                                        borderRadius: theme.spacing(0, 1, 1, 0),
                                    },
                                    '&.radius21':{
                                        borderRadius: theme.spacing(0, 0, 0, 0),
                                    },
                                    '&.radius3':{
                                        borderRadius: theme.spacing(0, 1, 1, 0),
                                    },
                                    '&.radius31':{
                                        borderRadius: theme.spacing(0, 0, 0, 0),
                                    },
                                    '&.radius4':{
                                        borderRadius: theme.spacing(0, 1, 1, 0),
                                    }
                                },
                                '&.radiusLast':{
                                    borderRadius: theme.spacing(0, 1, 1, 0),
                                },
                                '& .deliveryStepIconLeft':{
                                    float: 'left',
                                    width: 7,
                                    height: 8
                                },
                                '& .deliveryStepIconRight':{
                                    float: 'right',
                                    width: 7,
                                    height: 8
                                }
                            },
                            '&.webView': {
                                display: "none",
                                '& .headerItem': {
                                    flexGrow: 1,
                                    '&.noGrow': {
                                        flexGrow: 0
                                    }
                                }
                            },
                            '& .mobileTimerText':{
                                margin: '0 auto',
                                marginRight: 0,
                                marginTop: -21,
                                fontSize: 10,
                            },
                            '& .mobileTimerValue':{
                                fontSize: 16,
                                fontWeight: 700,
                                paddingTop: 4,
                                margin: '0 auto',
                                marginRight: 0,
                                marginTop: -16,
                            },
                            alignItems: "center",
                            '& .deliveryIcon': {
                                fill: "none",
                                '&.placed': {
                                    width: 20,
                                    height: 20,
                                },
                                '&.preparing': {
                                    width: 20,
                                    height: 20,
                                },
                                '&.onTheWay': {
                                    width: 20,
                                    height: 20,
                                },
                                '&.delivered': {
                                    width: 20,
                                    height: 20,
                                },
                                '&.cancelled': {
                                    width: 20,
                                    height: 20,
                                },
                            },
                            '& .deliveryText': {
                                fontSize: 14,
                                fontWeight: 600,
                                paddingLeft: theme.spacing(1),
                                paddingTop: theme.spacing(.5),
                                [theme.breakpoints.between(600, 650)]: {
                                    fontSize: 9,
                                },
                                [theme.breakpoints.between(650, 700)]: {
                                    fontSize: 10,
                                },
                                [theme.breakpoints.between(700, 800)]: {
                                    fontSize: 12,
                                },
                                '&.placed': {
                                    color: COLORS.TEXT_PRIMARY
                                },
                                '&.preparing': {
                                    color: COLORS.TEXT_PRIMARY
                                },
                                '&.onTheWay': {
                                    color: COLORS.TEXT_PRIMARY
                                },
                                '&.delivered': {
                                    color: COLORS.PERSIAN_GREEN
                                },
                                '&.cancelled': {
                                    color: COLORS.DARK_RED
                                },
                            },
                            '& .deliveryTextTimer': {
                                fontSize: 12,
                                fontWeight: 500,
                                paddingLeft: theme.spacing(1),
                                paddingTop: theme.spacing(.35),
                                color: COLORS.TEXT_PRIMARY
                            },
                            '& .deliveryTextTimerValue': {
                                fontSize: 18,
                                fontWeight: 700,
                                paddingLeft: theme.spacing(1),
                                color: COLORS.TEXT_PRIMARY
                            },
                            [theme.breakpoints.up('sm')]: {
                                '&.mobileView': {
                                    display: "none"
                                },
                                '&.webView': {
                                    display: "flex"
                                }
                            },
                        },
                        '& .progressTimeline': {
                            //paddingTop: theme.spacing(1),
                            '& .placed': {
                                '& .MuiStepConnector-alternativeLabel': {
                                    width: "100%",
                                    left: "-100%",
                                    '&.placed': {
                                        '& .MuiStepConnector-line': {
                                            borderColor: COLORS.TORY_BLUE
                                        }
                                    },
                                },
                                '& .stepIcon': {
                                    //right: "50%"
                                }
                            },
                            '& .preparing': {
                                '& .MuiStepConnector-alternativeLabel': {
                                    width: "102%",
                                    left: "-100%",
                                    '&.preparing': {
                                        '& .MuiStepConnector-line': {
                                            borderColor: COLORS.TORY_BLUE
                                        }
                                    },
                                    '&.onTheWay': {
                                        '& .MuiStepConnector-line': {
                                            borderColor: COLORS.TORY_BLUE
                                        }
                                    }
                                },
                                '& .stepIcon': {
                                    //right: "50%"
                                }
                            },
                            '& .onTheWay': {
                                '& .MuiStepConnector-alternativeLabel': {
                                    width: "130%",
                                    left: "-100%",
                                    '&.preparing': {
                                        '& .MuiStepConnector-line': {
                                            borderColor: COLORS.TORY_BLUE
                                        }
                                    },
                                    '&.onTheWay': {
                                        '& .MuiStepConnector-line': {
                                            borderColor: COLORS.TORY_BLUE
                                        }
                                    }
                                },
                                '& .stepIcon': {
                                    right: "70%"
                                }
                            },
                            '& .delivered': {
                                '& .MuiStepConnector-alternativeLabel': {
                                    width: "170%",
                                    left: "-72%",
                                    '&.onTheWay': {
                                        '& .MuiStepConnector-line': {
                                            borderColor: COLORS.TORY_BLUE
                                        }
                                    }
                                },
                                '& .stepIcon': {
                                    right: "60%"
                                }
                            }
                        },
                        '& .progressMsg': {
                            '& .progressStatusMsg': {
                                fontSize: 12,
                                fontWeight: 500,
                                paddingTop: 10,
                                textAlign: 'center',
                                color: COLORS.COPY
                            }
                        },
                        '& .progressArrivalLabel':{
                            fontSize: 10,
                        },
                        '& .progressArrivalTime':{
                            fontSize: 16,
                        }
                    }
                },
                '&.desc': {
                    padding: theme.spacing(6.25, 2, 2),
                    backgroundColor: COLORS.WHITE,
                    [theme.breakpoints.up('sm')]: {
                        backgroundColor: COLORS.SELAGO,
                        padding: theme.spacing(2, 2, 2),
                    },
                    '&.topPadding16':{
                        paddingTop: 16
                    },
                    '& .descContainer': {
                        maxWidth: 391,
                        margin: "0 auto",
                        [theme.breakpoints.up('sm')]: {
                            backgroundColor: COLORS.WHITE,
                            padding: theme.spacing(1.25),
                            border: `1px solid ${COLORS.CONCRETE}`,
                            borderRadius: theme.spacing(1),
                        },
                    },
                    '& .descItem': {
                        width: "100%",
                        '&.order': {
                            paddingBottom: theme.spacing(1)
                        },
                        '&.delivery': {
                            paddingTop: theme.spacing(2),
                        },
                        '& .orderDescContainer': {
                            flexDirection: "column",
                            '& .orderDescItem': {
                                paddingBottom: theme.spacing(0.5),
                                '& p': {
                                    fontSize: 12,
                                    paddingRight: theme.spacing(0.5),
                                    color: COLORS.TEXT_PRIMARY,
                                },
                                '& .address':{
                                    fontSize: 14,
                                    fontWeight: 500,
                                }
                            },
                        },
                        '& .orderContainer': {
                            padding: theme.spacing(.5, 0, 0, 0),
                            alignItems: "center",
                            justifyContent: "space-between",
                            '& .orderContainerItem': {
                                fontSize: 12,
                                fontWeight: 400,
                                color: COLORS.TEXT_PRIMARY
                            },
                            '& .orderContainerItemValue': {
                                fontSize: 14,
                                fontWeight: 600,
                                color: COLORS.TEXT_PRIMARY,
                                '&.custSupport':{
                                    color: COLORS.PRIMARY_MAIN,
                                }
                            }
                        }
                    }
                },
                '&.map': {
                    flexGrow: 1,
                    '& .mapContainer': {
                        width: "100%",
                        height: "100%",
                    },
                    '& .deliveredIconContainer':{
                        width: "100%",
                        height: "100%",
                        backgroundColor: COLORS.SECONDARY_MAIN
                    },
                    '& .deliveredIcon':{
                        width: 295,
                        height: 320,
                        position: "absolute",
                        top: 0,
                        bottom: '29%',
                        left: 0,
                        right: 0,
                        margin: 'auto',
                        [theme.breakpoints.up('sm')]: {
                            bottom: '4%',
                        },
                    },
                    '& .deliveryFailedContainer':{
                        width: "100%",
                        height: "70%",
                        backgroundColor: COLORS.SECONDARY_MAIN,
                        '&.mobileView': {
                            display: "flex",
                        },
                        [theme.breakpoints.up('sm')]: {
                            '&.mobileView': {
                                display: "none"
                            }
                        },
                    },
                    '& .deliveryFailedTextContainer':{
                        width: "100%",
                        height: "30%",
                        backgroundColor: COLORS.SECONDARY_MAIN,
                        '&.mobileView': {
                            display: "flex",
                        },
                        [theme.breakpoints.up('sm')]: {
                            '&.mobileView': {
                                display: "none"
                            }
                        },
                    },
                    '& .deliveryFailedIcon':{
                        width: 320,
                        height: 319,
                        position: "absolute",
                        top: 0,
                        bottom: '26%',
                        left: 20,
                        right: 0,
                        margin: 'auto',
                        '&.technicalIssue':{
                            left: 0,
                        },
                        '&.orderCancelled':{
                            left: 0,
                        }
                    },
                    '& .deliveryTextFailed':{
                        width: 320,
                        height: 88,
                        position: "absolute",
                        top: 0,
                        bottom: '-35%',
                        left: 0,
                        right: 0,
                        margin: 'auto',
                        fontSize: 16,
                        color: COLORS.TEXT_PRIMARY,
                        textAlign: 'center',
                        fontWeight: 500,
                        lineHeight: '20px',
                        '&.deliveryTextFailed1':{
                            fontSize: 14,
                            color: COLORS.COPY,
                            bottom: '-46%',
                        },
                    },
                    '& .deliveryFailedContainerWeb':{
                        width: "100%",
                        height: "70%",
                        backgroundColor: COLORS.WHITE,
                        '&.webView': {
                            display: "none",
                        },
                        [theme.breakpoints.up('sm')]: {
                            '&.webView': {
                                display: "flex"
                            }
                        },
                    },
                    '& .deliveryFailedTextContainerWeb':{
                        width: "100%",
                        height: "30%",
                        backgroundColor: COLORS.WHITE,
                        '&.webView': {
                            display: "none",
                        },
                        [theme.breakpoints.up('sm')]: {
                            '&.webView': {
                                display: "flex"
                            }
                        },
                    },
                    '& .deliveryFailedIconWeb':{
                        width: 295,
                        height: 295,
                        position: "absolute",
                        top: 0,
                        bottom: '10%',
                        left: 0,
                        right: 0,
                        margin: 'auto',
                    },
                    '& .deliveryTextFailedWeb':{
                        width: 600,
                        height: 88,
                        position: "absolute",
                        top: 0,
                        bottom: '65%',
                        left: 0,
                        right: 0,
                        margin: 'auto',
                        fontSize: 23,
                        color: COLORS.TEXT_PRIMARY,
                        textAlign: 'center',
                        fontWeight: 500,
                        lineHeight: '36px',
                        '&.orderCancelled':{
                            fontSize: 20,
                        }
                    },
                    '& .deliveryTextFailedWeb1':{
                        width: 600,
                        height: 78,
                        position: "absolute",
                        top: 0,
                        bottom: '55%',
                        left: 0,
                        right: 0,
                        margin: 'auto',
                        fontSize: 18,
                        color: COLORS.BLACK_LIGHT,
                        textAlign: 'center',
                        fontWeight: 'normal',
                        '&.orderCancelled':{
                            fontSize: 16,
                        }
                    },
                    '& .durationInfo': {
                        position: "absolute",
                        width: 380,
                        height: 46,
                        top: '40%',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        margin: 'auto',
                        '&.webView': {
                            display: "none",
                        },
                        [theme.breakpoints.up('sm')]: {
                            '&.webView': {
                                display: "flex"
                            }
                        },
                    }
                },
                '&.duration': {
                    '& .descContainer': {
                        
                    },
                    '& .descItem': {
                        width: "100%",
                        '&.order': {
                            paddingBottom: theme.spacing(1)
                        },
                        '&.delivery': {
                            backgroundColor: COLORS.WHITE,
                            padding: theme.spacing(1.25),
                            border: `1px solid ${COLORS.CONCRETE}`,
                            borderRadius: theme.spacing(1),
                            paddingTop: 16,
                            height: 46
                        },
                        '& .orderDescContainer': {
                            flexDirection: "column",
                            '& .orderDescItem': {
                                paddingBottom: theme.spacing(0.5),
                                '& p': {
                                    fontSize: 11,
                                    fontWeight: 500,
                                    paddingRight: theme.spacing(0.5)
                                }
                            },
                        },
                        '& .orderContainer': {
                            padding: theme.spacing(0, 1),
                            alignItems: "center",
                            justifyContent: "space-between",
                            '& .orderContainerItem': {
                                fontSize: 10,
                                fontWeight: 600,
                                color: COLORS.BOULDER
                            }
                        }
                    }
                },
                '&.help': {
                    '& .helpContainer': {
                        padding: theme.spacing(2),
                        '& p': {
                            fontSize: 11,
                            fontWeight: 500
                        },
                        '& .helpItem': {
                            paddingRight: theme.spacing(0.5)
                        },
                        [theme.breakpoints.up('sm')]: {
                            justifyContent: "center"
                        }
                    }
                },
                '& .footerContent': {
                    flexDirection: "column",
                    padding: theme.spacing(1, 2),
                    backgroundColor: COLORS.ZEUS,
                    '& .footerIcon': {
                        width: 148,
                        height: 60
                    },
                    [theme.breakpoints.up('sm')]: {
                        alignItems: "center"
                    }
                }
            }
        },
        trackingContent : {
            marginLeft: "2%",
            marginRight: "2%",
            marginTop: "6%",
            height: "100vh",
            width: '100%',
            overflow: 'auto',
            [theme.breakpoints.between(500, 600)]: {
                width: '90%',
                marginLeft: "5%",
                marginRight: "5%",
            },
            [theme.breakpoints.between(600, 700)]: {
                width: '80%',
                marginLeft: "10%",
                marginRight: "10%",
            },
            [theme.breakpoints.between(700, 800)]: {
                width: '70%',
                marginLeft: "15%",
                marginRight: "15%",
            },
            [theme.breakpoints.between(800, 900)]: {
                width: '60%',
                marginLeft: "20%",
                marginRight: "20%",
            },
            [theme.breakpoints.between(900, 1200)]: {
                width: '50%',
                marginLeft: "25%",
                marginRight: "25%",
            },
            [theme.breakpoints.up(1200)]: {
                width: '40%',
                marginLeft: "30%",
                marginRight: "30%",
            },
        },
        timeline: {
            padding: "0 !important",
            margin: 0,
            '& .timelineItem': {
              minHeight: 50,
              '&:last-child': {
                minHeight: "auto"
              },
              '&:before': {
                content: "none"
              },
              '& .timelineDot': {
                padding: 0,
                margin: 0,
                border: "none",
                boxShadow: "none",
                backgroundColor: "transparent",
                '& .dotIcon': {
                  width: 18,
                  height: 18,
                  padding: '4px 2px 2px 4px',
                  '&.active': {
                    padding: 0
                  }
                },
              },
              '& .primaryConnector': {
                backgroundColor: COLORS.TRANSPARENT,
                borderLeft: `1px dashed ${COLORS.SILVER}`
              },
              '& .timelineContent': {
                padding: 0,
                paddingLeft: theme.spacing(1),
                '& .heading': {
                  paddingTop: 1,
                  fontSize: 12,
                  [theme.breakpoints.up('sm')]: {
                    fontSize: 14,
                  },
                  lineHeight: "14px",
                  fontWeight: 600,
                  color: theme.palette.primary.main,
                  '&.error': {
                    color: COLORS.DARK_RED,
                    '&.active': {
                      paddingBottom: 17
                    }
                  },
                  '&.active': {
                    paddingBottom: 17
                  },
                  '& .dateTime': {
                    fontSize: 10,
                    [theme.breakpoints.up('sm')]: {
                        fontSize: 12,
                    },
                    lineHeight: "15px",
                    fontWeight: 500,
                    color: COLORS.DOVE_GRAY,
                    paddingLeft: 4,
                    verticalAlign: "bottom",
                  }
                },
                '& .metadataContainer': {
                  paddingBottom: theme.spacing(1.5),
                  '& .title': {
                    fontSize: 12,
                    [theme.breakpoints.up('sm')]: {
                        fontSize: 14,
                    },
                    lineHeight: "10px",
                    paddingBottom: theme.spacing(1),
                    fontWeight: 500,
                    color: COLORS.COPY
                  },
                  '& .description': {
                    fontSize: 10,
                    lineHeight: "10px",
                    color: COLORS.DOVE_GRAY_LIGHT
                  }
                }
              }
            }
          },
          customDialog: {
            '& .MuiDialogActions-root': {
              padding: "20px",
              marginTop: 20,
              marginBottom: 10,
              '& .customBtn':{
                minWidth: 134,
                height: 40
              }
            },
            '& .MuiDialog-paper':{
              height: "calc(100vh - 200px)",
              minWidth: 555
            },
            '& .MuiDialogTitle-root': {
              '& .MuiTypography-root':{
                fontSize: 18,
                fontWeight: 600,
                color: "#252525",
              }
            },
            '& .MuiDialogContent-root':{
              padding: '8px 24px 12px 30px',
              '& .MuiCollapse-container':{
                width: '100%'
              }
            },
            '& .MuiDialogContentText-root': {
              fontSize: 18,
              fontWeight: 600,
              color: "#252525",
              '& .MuiCollapse-container':{
                width: '100%'
              }
            },
            '& .titleStatus':{
              height: 21,
              fontWeight: 600,
              color: '#4D4D4D',
              marginBottom: 12,
              paddingLeft: 22,
              '&.trackingStatus':{
                fontSize: 16,
                [theme.breakpoints.up('sm')]: {
                    fontSize: 20,
                }
              },
              '&.carrier':{
                fontSize: 14,
                textAlign: 'right'
              }
            },
            '& .orderNumber':{
              height: 21,
              fontWeight: 600,
              color: '#4D4D4D',
              marginBottom: 20,
              '&.orderLabel':{
                fontSize: 10,
                [theme.breakpoints.up('sm')]: {
                    fontSize: 12,
                },
                paddingLeft: 22
              },
              '& .orderValue':{
                color: '#0E5AA7',
              },
              '&.logo':{
                textAlign: 'right'
              }
            },
            '& .orderDate':{
              height: 21,
              fontWeight: 600,
              color: '#4D4D4D',
              marginBottom: 20,
              '&.orderDateLabel':{
                fontSize: 12,
                [theme.breakpoints.up('sm')]: {
                    fontSize: 14,
                },
                textDecorationLine: 'underline',
                color: '#000000',
              },
            },
            '& .StatusProgressMain':{
              '& .timelineItem': {
                minHeight: '36px !important',
                '& .marginLeft':{
                  marginLeft: -3
                }
              },
              '& .timelineContent':{
                paddingLeft: '12px !important'
              },
              '& .primaryConnector':{
                margin: '0px',
                backgroundImage: 'repeating-linear-gradient(10deg, #DCDCDC, #DCDCDC 7px, transparent 7px, transparent 15px)',
                border: 'none'
              },
              '& .lastConnector':{
                flexGrow: '0.7'
              },
              '& .timer':{
                fontSize: 12,
                lineHeight: "15px",
                color: COLORS.DOVE_GRAY_LIGHT,
                padding: '0px 10px 0px 0px',
                '&.first':{
                  minWidth: 22,
                },
                '&.notFirst':{
                  minWidth: 25,
                }
              },
              '& .heading':{
                lineHeight: '17px !important'
              },
              '& .metadataContainer':{
                paddingBottom: '20px !important',
                width: '80%',
                '&.viewMore':{
                  paddingBottom: '10px !important'
                },
                '& .border':{
                  height: 44,
                  border: '0.5px solid #C3C3C3',
                  boxSizing: 'border-box',
                  borderRadius: 4,
                  padding: '3px 0 0px 8px',
                  marginLeft: '-8px'
                }
              },
              '& .title':{
                lineHeight: '17px !important',
                '&.failed':{
                  color: '#3C1010 !important'
                }
              },
              '& .notFound':{
                fontSize: 14,
                paddingLeft: 22,
              },
              '& .arrow':{
                marginRight: 8,
                '& .addressLogo': {
                  width: 14,
                  height: 14,
                  transform: "rotate(270deg)",
                  transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                  '&.open': {
                    transform: "rotate(0deg)",
                  }
                }
              },
              '& .viewMore':{
                color: `${theme.palette.primary.main} !important`,
                fontWeight: '600 !important',
                //background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 50.52%)'
              }
            },
            '& .orderTimeLine':{
              height: 15,
              fontWeight: 500,
              color: '#4D4D4D',
              marginBottom: 20,
              '&.orderTime':{
                fontSize: 12,
                color: '#4D4D4D',
              },
              '&.smallDotIcon':{
                paddingTop: 2
              },
              '&.dotIconDiv':{
                paddingLeft: 12,
              },
              '&.smallDotIconDiv':{
                paddingLeft: 18,
                paddingTop: 7,
              },
              '& .status':{
                fontSize: 14,
                color: '#0B4C8E',
                fontWeight: 600,
              },
              '& .date':{
                fontSize: 12,
                color: '#4D4D4D',
                fontWeight: 500,
              },
              '& .arrow':{
                marginLeft: 8,
                '& .addressLogo': {
                  width: 14,
                  height: 14,
                  transform: "rotate(0deg)",
                  transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                  '&.open': {
                    transform: "rotate(180deg)",
                  }
                }
              },
              '&.summary':{
                '& .title':{
                  fontSize: 12,
                },
                '& .date':{
                  fontSize: 10,
                },
                '&.border':{
                  border: '0.5px solid #C3C3C3',
                  boxSizing: 'border-box',
                  borderRadius: 4,
                  height:'auto',
                  padding: theme.spacing(0.5, 0.5, 0.5,0.25),
                  '& .failedColor':{
                      color: '#3C1010'
                    }
                  }
              },
              '& .primaryConnector': {
                backgroundColor: COLORS.TRANSPARENT,
                borderLeft: `1px dashed ${COLORS.SILVER}`,
                marginLeft: 24
              },
            },
            '& .summaryRow':{
              marginBottom: 20,
              '&.failedColor':{
                marginBottom: 0,
              }
            }
            
          },
          closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
          },
        timelineStepper: {
            padding: 0,
            alignItems: "center",
            backgroundColor: 'transparent',
            '& .timelineStep': {
                paddingLeft: 0,
                paddingRight: 0,
                '& .stepIcon': {
                    width: 5,
                    height: 5,
                    position: "absolute",
                    zIndex: 99,
                    '& ellipse': {
                        fill: COLORS.SILVER_LIGHT
                    },
                    '&.placed': {
                        '& ellipse': {
                            fill: COLORS.TORY_BLUE,
                            fillOpacity: 1
                        },
                    },
                    '&.preparing': {
                        '& ellipse': {
                            fill: COLORS.TORY_BLUE,
                            fillOpacity: 1
                        },
                    },
                    '&.onTheWay': {
                        '& ellipse': {
                            fill: COLORS.TORY_BLUE,
                            fillOpacity: 1
                        },
                    },
                    '&.delivered': {
                        '& ellipse': {
                            fill: COLORS.PERSIAN_GREEN,
                            fillOpacity: 1
                        },
                    },
                    '&.cancelled': {
                        '& ellipse': {
                            fill: COLORS.DARK_RED,
                            fillOpacity: 1
                        }
                    }
                }
            },
        },
    }),
);