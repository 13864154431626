/* global google */
import React, { useRef, useCallback, useEffect, useState } from 'react';
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
  Polyline
} from "react-google-maps";
import { CustomMarker, CustomLocation } from ".";
import MapDirectionsRenderer from "./MapDirectionRenderer";
import { useSelector } from 'react-redux';
import { AppState } from 'config/redux/reducers';
import useLanguageHook from 'hooks/useLanguage.hook';

interface CustomGoogleMapProps {
  markers: CustomMarker[];
  places: CustomMarker[];
  showDirections?: boolean;
  roundTrip?: boolean;
  optimizeRoute?: boolean;
  updateMap?: boolean;
  defaultCenter: CustomLocation;
  defaultZoom: number;
  status?: any;
  handleCurrentZoomLevel: (zoomLevel: any) => void;
  source ?: string;
}
const CustomGoogleMap = withScriptjs(
  withGoogleMap((props: CustomGoogleMapProps) => {
    const { markers, places, defaultCenter, defaultZoom, showDirections, roundTrip, optimizeRoute, updateMap, status, handleCurrentZoomLevel, source } = props; 

    const { durations, polylinePointsOFD } = useSelector((state: AppState) => state.common);
    
    const [positionOne, setPositionOne] = useState(new google.maps.LatLng(0, 0));
    const [positionTwo, setPositionTwo] = useState(new google.maps.LatLng(0, 0));
    const [mapCenter, setMapCenter] = useState(defaultCenter);
    const [isDragged, setIsDragged] = useState(false);
    const [polylineOptionsInfo, setPolylineOptionsInfo] = useState({});
    const [polyLinePath, setPolyLinePath] = useState([] as any);
    const language = useLanguageHook()
    const mapRef = useRef<GoogleMap>(null);

    const handleZoomChanged = useCallback(
      () => {
        const currentZoomLevel = mapRef.current?.getZoom();
        if (currentZoomLevel && currentZoomLevel !== defaultZoom) {
          handleCurrentZoomLevel(currentZoomLevel)
        }
      },
      [defaultZoom, handleCurrentZoomLevel]
    )

    const handleOnDragEnd = useCallback(
      () => {
        const currentMapCenterLat = mapRef.current?.getCenter().lat();
        const currentMapCenterLng = mapRef.current?.getCenter().lng();
        if(currentMapCenterLat && currentMapCenterLng){
          const currentMapCenter = {lat : currentMapCenterLat, lng: currentMapCenterLng}
          if (currentMapCenter && (currentMapCenterLat !== mapCenter.lat || currentMapCenterLng !== mapCenter.lng)) {
            setMapCenter(currentMapCenter)
            setIsDragged(true)
          }
        }
      },
      [mapCenter]
    )

    useEffect(() => {
      if (!durations && !isDragged) {
        const bounds = new google.maps.LatLngBounds()
        markers.forEach((marker, index) => {
          const position = new google.maps.LatLng(marker.location.lat, marker.location.lng);
          bounds.extend(position);
        });
        mapRef.current?.fitBounds(bounds)

        // const currentMapCenterLat = mapRef.current?.getCenter().lat();
        // const currentMapCenterLng = mapRef.current?.getCenter().lng();
        // if(currentMapCenterLat && currentMapCenterLng){
        //   const currentMapCenter = {lat : currentMapCenterLat, lng: currentMapCenterLng}
        //   if (currentMapCenter && (currentMapCenterLat !== mapCenter.lat || currentMapCenterLng !== mapCenter.lng)) {
        //     setMapCenter(currentMapCenter)
        //   }
        // }
        setPositionOne(new google.maps.LatLng(markers[0].location.lat, markers[0].location.lng));
        setPositionTwo(new google.maps.LatLng(markers[1].location.lat, markers[1].location.lng));
      }
    }, [markers, durations, mapCenter, isDragged]);

    const B1 = (t:any) => {
      return t * t * t;
    };
    const B2 = (t:any) => {
      return 3 * t * t * (1 - t);
    }
    const B3 = (t:any) => {
      return 3 * t * (1 - t) * (1 - t);
    }
    const B4 = (t:any) => {
      return (1 - t) * (1 - t) * (1 - t);
    }
  
    const getBezier = useCallback(
      (C1:any, C2:any, C3:any, C4:any, percent:any) => {
        let pos = {x:0,y:0};
        pos.x = C1.x * B1(percent) + C2.x * B2(percent) + C3.x * B3(percent) + C4.x * B4(percent);
        pos.y = C1.y * B1(percent) + C2.y * B2(percent) + C3.y * B3(percent) + C4.y * B4(percent);
        return pos;
    },
    [])

    const GmapsCubicBezier = useCallback(
      (latlong1:any, latlong2:any, latlong3:any, latlong4:any, resolution:any) => {
      var lat1 = latlong1.lat();
      var long1 = latlong1.lng();
      var lat2 = latlong2.lat();
      var long2 = latlong2.lng();
      var lat3 = latlong3.lat();
      var long3 = latlong3.lng();
      var lat4 = latlong4.lat();
      var long4 = latlong4.lng();
  
      var points = [];
  
      for (let it = 0; it <= 1; it += resolution) {
          points.push(getBezier({
              x: lat1,
              y: long1
          }, {
              x: lat2,
              y: long2
          }, {
              x: lat3,
              y: long3
          }, {
              x: lat4,
              y: long4
          }, it));
      }
      var path = [];
      for (var i = 0; i < points.length - 1; i++) {
        path.push(new google.maps.LatLng(points[i].x, points[i].y));
        path.push(new google.maps.LatLng(points[i + 1].x, points[i + 1].y, false));
      }
      setPolyLinePath(path);
      setPolylineOptionsInfo({
        path: path,
        geodesic: true,
        strokeOpacity: 0.0,
          icons: [{
            icon: {
              path: 'M 0,-1 0,1',
              strokeOpacity: 1,
              scale: 4
            },
            offset: '0',
            repeat: '20px'
          }],
          strokeColor: 'grey'
      });
  }, [getBezier])
  useEffect(() => {
    if(mapRef.current){
      var lineLength = google.maps.geometry.spherical.computeDistanceBetween(positionOne, positionTwo);
      var lineHeading = google.maps.geometry.spherical.computeHeading(positionOne, positionTwo);
      var markerA = new google.maps.Marker({
        position: google.maps.geometry.spherical.computeOffset(positionOne, lineLength / 3, lineHeading + 60),
        icon: {
          url: "https://maps.gstatic.com/intl/en_us/mapfiles/markers2/measle_blue.png",
          size: new google.maps.Size(7, 7),
          anchor: new google.maps.Point(3.5, 3.5)
        }
      });
      var markerB = new google.maps.Marker({
        position: google.maps.geometry.spherical.computeOffset(positionTwo, lineLength / 3, lineHeading + 120),
        icon: {
          url: "https://maps.gstatic.com/intl/en_us/mapfiles/markers2/measle_blue.png",
          size: new google.maps.Size(7, 7),
          anchor: new google.maps.Point(3.5, 3.5)
        },
      });
      GmapsCubicBezier(positionOne, markerA.getPosition(), markerB.getPosition(), positionTwo, 0.01);
    }
  }, [positionOne, positionTwo,GmapsCubicBezier]);

    return (
      <GoogleMap
        key={Math.floor(Math.random() * 1000)}
        defaultZoom={defaultZoom}
        ref={mapRef}
        onZoomChanged={handleZoomChanged}
        onDragEnd={handleOnDragEnd}
        options={{ fullscreenControl: false, zoomControl: false, mapTypeControl : false, streetViewControl: false, clickableIcons: false, styles: [{ elementType: "labels", featureType: "poi", stylers: [{ visibility: "off", }], }], }}
      >
        {
          markers.length &&
          markers.map((marker: CustomMarker) => (
          <React.Fragment key={`${marker.name}-${Math.floor(Math.random() * 1000)}`}>
            {
            marker.name !== 'Driver' ? 
            <Marker title={marker.name} icon={{url : marker.icon, anchor: new google.maps.Point(35, 32)}} label={marker.label} position={marker.location} />
            :
            <Marker title={marker.name} icon={marker.icon} label={marker.label} position={marker.location} />
            }
          </React.Fragment>
          ))
        }
        {source ? 
          <>
          {
            (status === language.DELIVERY_STATUS.ORDER_PLACED || status === language.DELIVERY_STATUS.ORDER_BEING_PREPARED) && 
            <Polyline path={polyLinePath} options={polylineOptionsInfo} />
          }
          {
            showDirections && (markers && markers?.length > 1) && status === language.DELIVERY_STATUS.ORDER_ON_THE_WAY && polylinePointsOFD &&
            <Polyline path={google.maps.geometry.encoding.decodePath(polylinePointsOFD)} options={{ strokeColor: '#0B5AA6' }} />
          }
        </>
        : <Polyline path={polyLinePath} options={polylineOptionsInfo} />
        }
      </GoogleMap>
    )
  })
);

export default CustomGoogleMap;
