import React, { useCallback, useEffect, useState } from 'react';
import qs from 'qs';
//import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from '@material-ui/lab';
import  Timeline from '@material-ui/lab/Timeline';
import  TimelineItem from '@material-ui/lab/TimelineItem';
import  TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import  TimelineDot from '@material-ui/lab/TimelineDot';
import  TimelineConnector from '@material-ui/lab/TimelineConnector';
import  TimelineContent from '@material-ui/lab/TimelineContent';
import { Grid, SvgIcon, Typography, Collapse } from '@material-ui/core';
import { getTimeStringFromUTC, getDayAndMonthName } from "../utils/helpers.utils";
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './OrderTrackingMkp.styles';
import { AppState } from 'config/redux/reducers';
import { fetchOrderStatus } from 'config/redux/common/commonSlice';
import { ReactComponent as activeTimelineDot } from './../assets/icons/active-timeline-dot.svg';
import { ReactComponent as timelineDot } from './../assets/icons/timeline-dot.svg';
import { ReactComponent as downArrowIcon } from './../assets/icons/down-arrow.svg';

interface OrderTrackingMkpProps {
    location?: any;
    match?: any;
}

const OrderTrackingMkp: any = (props: OrderTrackingMkpProps) => {
    const classes = useStyles();

    const dispatch = useDispatch();

    const { location } = props;

    const { consignmentData } = useSelector(
        (state: AppState) => state.common
    );
    
    const [lastStatusUpdate, setLastStatusUpdate] = useState<any>({});
    const [trackingStatus, setTrackingStatus] = useState<any>({});
    const [metadata, setMetaData] = useState<any>({});
    const [toggleOrderPlaced, setToggleOrderPlaced] = useState(true);
    const [toggleOrderPrepared, setToggleOrderPrepared] = useState(false);
    const [toggleOrderOnTheWay, setToggleOrderOnTheWay] = useState(false);
    const [toggleOrderDelivered, setToggleOrderDelivered] = useState(false);
    const [toggleOrderDeliveryFailed, setToggleOrderDeliveryFailed] = useState(false);
    const [toggleOrderCancelled, setToggleOrderCancelled] = useState(false);
    const [orderPlacedItemStyle, setOrderPlacedItemStyle] = useState({ display: 'none' });
    const [orderPreparedItemStyle, setOrderPreparedItemStyle] = useState({ display: 'none' });
    const [orderOnTheWayItemStyle, setOrderOnTheWayItemStyle] = useState({ display: 'none' });
    const [orderDeliveredItemStyle, setOrderDeliveredItemStyle] = useState({ display: 'none' });
    const [orderDeliveryFailedItemStyle, setOrderDeliveryFailedItemStyle] = useState({ display: 'none' });
    const [orderCancelledItemStyle, setOrderCancelledItemStyle] = useState({ display: 'none' });
    const [viewMoreOrderPlaced, setViewMoreOrderPlaced] = useState(false);
    const [viewMoreOrderPrepared, setViewMoreOrderPrepared] = useState(false);
    const [viewMoreOrderOnTheWay, setViewMoreOrderOnTheWay] = useState(false);
    const [viewMoreOrderDelivered, setViewMoreOrderDelivered] = useState(false);
    const [viewMoreOrderDeliveryFailed, setViewMoreOrderDeliveryFailed] = useState(false);
    const [viewMoreOrderCancelled, setViewMoreOrderCancelled] = useState(false);


    const getConsignmentData = useCallback(
        () => {
            const payload: any = location.search ? qs.parse(location.search, { ignoreQueryPrefix: true, decoder: (c) => c }) : { code: "" };
            dispatch(
              fetchOrderStatus({
                    consignmentCode: payload.code,
                    carrier: "cams"
                })
            )
        },
        [dispatch, location],
    );

    useEffect(() => {
        let trackingStatusArr: any = {};
        if (consignmentData.statuses && consignmentData.statuses.length) {
            let trackingStatusList = consignmentData.statuses.slice();
            trackingStatusList = trackingStatusList.reverse();
            let latestStatusUpdate = trackingStatusList.length ? trackingStatusList[trackingStatusList.length -1] : '';
            setMetaData(consignmentData.metadata);
            setLastStatusUpdate(latestStatusUpdate);
            trackingStatusArr =  trackingStatusList.reduce((acc:any, obj:any) => {
              const key = obj['statusGroupCode'];
              if(key && (key === 'ORDER_PLACED' || key === 'ORDER_BEING_PREPARED' || key === 'ORDER_ON_THE_WAY' || key === 'ORDER_DELIVERED' || key === 'UNABLE_TO_DELIVER' || key === 'ORDER_CANCELLATION')){
                if (!acc[key]) {
                  acc[key] = [];
                }
                acc[key].push(obj);
              }
            return acc;
           }, {});
          }
          setTrackingStatus(trackingStatusArr)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consignmentData]);

    useEffect(() => {
        getConsignmentData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTrackingTimelineViewBox = (index: any) => {
        if(index === 'ORDER_PLACED'){
          return "0 0 18 18"
        }else{
          return "0 0 10 10"
        }
      }
    
      const getGroupStatusInfo = (index: any) => {
        if(index === 'ORDER_PLACED'){
          return toggleOrderPlaced
        }else if(index === 'ORDER_BEING_PREPARED'){
          return toggleOrderPrepared
        }else if(index === 'ORDER_ON_THE_WAY'){
          return toggleOrderOnTheWay
        }else if(index === 'ORDER_DELIVERED'){
          return toggleOrderDelivered
        }else if(index === 'UNABLE_TO_DELIVER'){
          return toggleOrderDeliveryFailed
        }else if(index === 'ORDER_CANCELLATION'){
          return toggleOrderCancelled
        }
      }
    
      const toggleTrackingStatus = (index: any) => {
        if(index === 'ORDER_PLACED'){
          setToggleOrderPlaced(!toggleOrderPlaced)
        }else if(index === 'ORDER_BEING_PREPARED'){
          setToggleOrderPrepared(!toggleOrderPrepared)
        }else if(index === 'ORDER_ON_THE_WAY'){
          setToggleOrderOnTheWay(!toggleOrderOnTheWay)
        }else if(index === 'ORDER_DELIVERED'){
          setToggleOrderDelivered(!toggleOrderDelivered)
        }else if(index === 'UNABLE_TO_DELIVER'){
          setToggleOrderDeliveryFailed(!toggleOrderDeliveryFailed)
        }else if(index === 'ORDER_CANCELLATION'){
          setToggleOrderCancelled(!toggleOrderCancelled)
        }
      }
    
      const checkIfNotLastTimeLine = (index: any) => {
        if(lastStatusUpdate && index !== lastStatusUpdate.statusGroupCode){
          return true
        }else {
          return false
        }
      }
    
      const getItemStyle = (index: any) => {
        if(index === 'ORDER_PLACED'){
          return orderPlacedItemStyle
        }else if(index === 'ORDER_BEING_PREPARED'){
          return orderPreparedItemStyle
        }else if(index === 'ORDER_ON_THE_WAY'){
          return orderOnTheWayItemStyle
        }else if(index === 'ORDER_DELIVERED'){
          return orderDeliveredItemStyle
        }else if(index === 'UNABLE_TO_DELIVER'){
          return orderDeliveryFailedItemStyle
        }else if(index === 'ORDER_CANCELLATION'){
          return orderCancelledItemStyle
        }
      }
    
      const getGroupValueViewMoreFlag = (index: any) => {
        if(index === 'ORDER_PLACED'){
          return viewMoreOrderPlaced
        }else if(index === 'ORDER_BEING_PREPARED'){
          return viewMoreOrderPrepared
        }else if(index === 'ORDER_ON_THE_WAY'){
          return viewMoreOrderOnTheWay
        }else if(index === 'ORDER_DELIVERED'){
          return viewMoreOrderDelivered
        }else if(index === 'UNABLE_TO_DELIVER'){
          return viewMoreOrderDeliveryFailed
        }else if(index === 'ORDER_CANCELLATION'){
          return viewMoreOrderCancelled
        }
      }
    
      const viewMoreDetails = (index: any) => {
        if(index === 'ORDER_PLACED'){
          setViewMoreOrderPlaced(true);
          setOrderPlacedItemStyle({display: 'block'})
        }else if(index === 'ORDER_BEING_PREPARED'){
          setViewMoreOrderPrepared(true);
          setOrderPreparedItemStyle({display: 'block'})
        }else if(index === 'ORDER_ON_THE_WAY'){
          setViewMoreOrderOnTheWay(true);
          setOrderOnTheWayItemStyle({display: 'block'})
        }else if(index === 'ORDER_DELIVERED'){
          setViewMoreOrderDelivered(true);
          setOrderDeliveredItemStyle({display: 'block'})
        }else if(index === 'UNABLE_TO_DELIVER'){
          setViewMoreOrderDeliveryFailed(true);
          setOrderDeliveryFailedItemStyle({display: 'block'})
        }else if(index === 'ORDER_CANCELLATION'){
          setViewMoreOrderCancelled(true);
          setOrderCancelledItemStyle({display: 'block'})
        }
      }
    
      const viewLessDetails = (index: any) => {
        if(index === 'ORDER_PLACED'){
          setViewMoreOrderPlaced(false);
          setOrderPlacedItemStyle({display: 'none'})
        }else if(index === 'ORDER_BEING_PREPARED'){
          setViewMoreOrderPrepared(false);
          setOrderPreparedItemStyle({display: 'none'})
        }else if(index === 'ORDER_ON_THE_WAY'){
          setViewMoreOrderOnTheWay(false);
          setOrderOnTheWayItemStyle({display: 'none'})
        }else if(index === 'ORDER_DELIVERED'){
          setViewMoreOrderDelivered(false);
          setOrderDeliveredItemStyle({display: 'none'})
        }else if(index === 'UNABLE_TO_DELIVER'){
          setViewMoreOrderDeliveryFailed(false);
          setOrderDeliveryFailedItemStyle({display: 'none'})
        }else if(index === 'ORDER_CANCELLATION'){
          setViewMoreOrderCancelled(false);
          setOrderCancelledItemStyle({display: 'none'})
        }
      }


    const getTrackingStatusInfo = () => {
        return <>
          {trackingStatus && Object.keys(trackingStatus).length ? Object.keys(trackingStatus).map((index: any, itemInfo: any) => (
            <TimelineItem key={`${index}`} className="timelineItem">
              <Typography className={["timer", index === 'ORDER_PLACED' ? "first" : "notFirst"].join(" ")}>&nbsp;</Typography>
              <TimelineSeparator className={index !== 'ORDER_PLACED' ? 'marginLeft' : ''}>
                <TimelineDot className="timelineDot">
                  <SvgIcon className={["dotIcon", index === 'ORDER_PLACED' ? "active" : ""].join(" ")} component={index === 'ORDER_PLACED' ? activeTimelineDot : timelineDot} viewBox={getTrackingTimelineViewBox(index)} />
                </TimelineDot>
                {
                checkIfNotLastTimeLine(index) ? 
                <TimelineConnector className="primaryConnector" /> : 
                (getGroupStatusInfo(index) ? <TimelineConnector className="primaryConnector lastConnector" /> : null) 
                }
              </TimelineSeparator>
              <TimelineContent className="timelineContent">
                <Typography className="heading active" onClick={() => toggleTrackingStatus(index)}>
                  <span className="arrow">
                    <SvgIcon className={["addressLogo", getGroupStatusInfo(index) ? "open" : ""].join(" ")} component={downArrowIcon} />
                  </span>
                  <span className="status">{trackingStatus[index][0].statusGroupName}</span>
                  <span className="dateTime">&nbsp; | &nbsp;</span>
                  <span className="dateTime">{trackingStatus[index][0].statusDate ? getDayAndMonthName(trackingStatus[index][0].statusDate, metadata.timeZone) : ' | --'}</span>
                  
                </Typography>
                <Collapse in={getGroupStatusInfo(index)}>
                  {
                    trackingStatus[index].map((itemInfo: any, indexInner: any) => (
                      <Typography key={indexInner} component="div" className="metadataContainer" style={indexInner > 5 ? getItemStyle(index) : {}}>
                        {
                        itemInfo.statusError ? 
                        <Typography component="div" className="border">
                          <Typography className="title failed">{itemInfo.statusDescription}</Typography>
                          <Typography className="description">{`${getDayAndMonthName(itemInfo.statusDate, metadata.timeZone)} at ${getTimeStringFromUTC(itemInfo.statusDate, metadata.timeZone, true)}`}</Typography>
                       </Typography>
                       :
                       <>
                        <Typography className="title">{itemInfo.statusDescription}</Typography>
                        <Typography className="description">{`${itemInfo.statusDate ? getDayAndMonthName(itemInfo.statusDate, metadata.timeZone) : '--'} at ${itemInfo.statusDate ? getTimeStringFromUTC(itemInfo.statusDate, metadata.timeZone, true) : '--'}`}</Typography>
                       </>
                      }
                      </Typography>
                    ))
                  }
                  {
                  trackingStatus[index].length > 6 && 
                  <Typography key={`view-more-${index}`} component="div" className="metadataContainer viewMore">
                    <Typography className="title viewMore" onClick={() => getGroupValueViewMoreFlag(index) ? viewLessDetails(index) : viewMoreDetails(index)}>{getGroupValueViewMoreFlag(index) ? `view less` :`view more`}</Typography>
                  </Typography> 
                  }
                </Collapse>
              </TimelineContent>
            </TimelineItem>
          )) : (<Typography className="notFound">Shipment details currently not available</Typography>)
          }
          </>
      }

    return (
        <Grid container className={classes.root}>
            <Grid item className={classes.trackingContent +' '+classes.customDialog}>
                <Grid container>
                    <Grid className="titleStatus trackingStatus" item xs={12}>Shipment Details</Grid>
                </Grid>
                <Grid container>
                    <Grid className="orderNumber orderLabel" item xs={12}>Order Number : <span className="orderValue">{consignmentData && consignmentData.orderCode}</span> &nbsp; | &nbsp; Delivery By : <span>{consignmentData && consignmentData.deliveryInformation && consignmentData.deliveryInformation.carrier}</span></Grid>
                </Grid>
                <Grid container className="StatusProgressMain">
                    <Timeline className={classes.timeline} align="left">
                    {getTrackingStatusInfo()}
                    </Timeline>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default OrderTrackingMkp;