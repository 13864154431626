import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { COLORS } from 'config/theme/baseTheme';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: COLORS.PRIMARY_MAIN,
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(2, 2.5),
      '& .mafLogoIcon': {
        width: 138,
        height: 34
      },
      '& .languageIcon': {
        width: 33,
        height: 33,
        fill: "none"
      },
      '& .left': {
        [theme.breakpoints.up(600)]: {
          marginLeft: '4%',
        },
      },
      '& .right': {
        [theme.breakpoints.up(600)]: {
          marginRight: '6%',
        },
      }
    }
  }),
);